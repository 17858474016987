import React from 'react';

import Layout from '../components/Layout';

const signed = () => {
  return (
    <Layout location={{ pathname: 'signed' }}>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        <h2
          style={{
            textAlign: 'center',
            color: 'hsl(0, 0%, 20%)',
          }}
        >
          Special offer on Carol's book,
          <br /> "God, My Rock"
        </h2>
        <p style={{ fontSize: '1.3rem', lineHeight: '1.9rem' }}>
          We want to offer you an autographed copy of Carol's book&nbsp;
          <strong>God, My Rock</strong>,{' '}
          <em>Faith Adventures of a Missionary Mom</em>, at the discounted price
          of <strong>$10</strong> (shipping included).
        </p>
        <p style={{ fontSize: '1.3rem', lineHeight: '1.9rem' }}>
          To order,{' '}
          <a
            href='https://go2rpi.com/god-my-rock'
            target='_blank'
            rel='noopener noreferrer'
          >
            click here
          </a>{' '}
          and enter the coupon code <strong>signed20</strong> during the
          checkout process.
        </p>
      </div>
    </Layout>
  );
};

export default signed;
