import { Link } from 'gatsby';
import React, { useState } from 'react';
import FbIcon from './FbIcon';

const Header = ({ siteTitle = '', location }) => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);

  const currentPath = location.pathname;
  const navLinks = [
    { title: 'Home', to: '/' },
    { title: 'Book', to: '/book' },
    { title: 'Artwork', to: '/artwork' },
    { title: 'Bio', to: '/bio' },
    { title: 'Media', to: '/media' },
    { title: 'Links', to: '/links' },
  ];

  return (
    <header
      style={{
        marginBottom: `3rem`,
        marginTop: '1rem',
        paddingTop: '0.5rem',
      }}
    >
      <div
        style={{
          display: `flex`,
          justifyContent: `space-between`,
          alignItems: `center`,
          maxWidth: '1060px',
          margin: '0 auto',
        }}
      >
        <div style={{}}>
          <h1
            style={{
              margin: 0,
              padding: `0 1rem`,
              fontSize: `2.5rem`,
              fontWeight: '400',
            }}
          >
            <Link
              to='/'
              style={{
                color: `rebeccapurple`,
                textDecoration: `none`,
                fontSize: '3rem',
              }}
            >
              {siteTitle}
            </Link>
          </h1>
        </div>
        <div>
          <nav className='Nav'>
            <ul className='Nav__desktop'>
              {navLinks.map(({ title, to }) => (
                <li key={to}>
                  <Link
                    className={`Nav__Link ${
                      to === currentPath ? 'Nav__Link-current' : null
                    }`}
                    to={to}
                  >
                    {title}
                  </Link>
                </li>
              ))}
              <li>
                <a
                  style={{
                    paddingInline: '8px',
                    paddingBlock: '4px',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                  }}
                  href='https://www.facebook.com/Carol-Nordman-author-and-artist-102422238197339'
                >
                  <FbIcon />
                </a>
              </li>
            </ul>

            <div className='mobile-menu-svg-div'>
              {showMenu ? (
                <svg
                  className='mobile-menu-svg'
                  onClick={toggleMenu}
                  width='34'
                  height='34'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6 18L18 6M6 6L18 18'
                    stroke='#663399'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              ) : (
                <svg
                  className='mobile-menu-svg'
                  onClick={toggleMenu}
                  width='34'
                  height='34'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M4 6H20M4 12H20M4 18H20'
                    stroke='#663399'
                    strokeWidth='2'
                    strokeLinecap='straight'
                    strokeLinejoin='round'
                  />
                </svg>
              )}
            </div>
          </nav>
        </div>
      </div>
      {showMenu ? (
        <nav
          className={`Nav__mobile 
        
        `}
        >
          <ul>
            <li>
              <Link className='Nav__Link' to='/'>
                Home
              </Link>
            </li>
            <li>
              <Link className='Nav__Link' to='/artwork'>
                Artwork
              </Link>
            </li>
            <li>
              <Link className='Nav__Link' to='/book'>
                Book
              </Link>
            </li>
            <li>
              <Link className='Nav__Link' to='/bio'>
                My Bio
              </Link>
            </li>
            <li>
              <Link className='Nav__Link' to='/videos'>
                <div style={{ whiteSpace: 'nowrap' }}>Videos</div>
              </Link>
            </li>
            <li>
              <Link className='Nav__Link' to='/links'>
                Links
              </Link>
            </li>
            <li>
              <a
                style={{
                  paddingInline: '8px',
                  paddingBlock: '16px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                }}
                href='https://www.facebook.com/Carol-Nordman-author-and-artist-102422238197339'
              >
                <FbIcon />
              </a>
            </li>
          </ul>
        </nav>
      ) : null}
    </header>
  );
};

export default Header;
