/** * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Header from './Header';

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} location={location} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 0.45rem`,
          display: `flex`,
          flexDirection: `column`,
          minHeight: `87vh`,
        }}
      >
        <main style={{ flex: '1' }}>{children}</main>
        <footer style={{ flex: '0' }}>
          <div style={{ fontSize: `1.5rem`, margin: '2rem' }}>
            <a
              className='my-button'
              href='https://carolnordman.artspan.com/contact'
              target='_blank'
              rel='noreferrer noopener'
            >
              Contact Me
            </a>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Layout;
